// Footer.js
import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Box from '@mui/material/Box';

const Footer = ({ footerText, contact }) => (
  <footer className="footer">
    <div className="footer-content">
    <br/><br/>
    <div class="centered-image-container">
        <img src="https://ic2s2-2023.org/images/logo_carlsberg.png" class="centered-image" alt="Logo"/>
    </div>
    <br/>
    <div className="moon-symbol">☾</div>
    <br/>
        <div className='spnsors'>
            <BlockContent blocks={footerText} />
        </div>
      
      <br/>
      <div className="moon-symbol">☾</div>
      <br/>
      <Box>
        <h4>Contact</h4>
        <p>{contact.address}</p>
        <BlockContent blocks={contact.description} />
      </Box>
      <div className="moon-symbol">☾</div>
      <br/>
      <p>Design by <a href="https://victorsteemans.net/" target="_blank" rel="noopener noreferrer">Victor Steemans</a> & <a href="https://pietersteyaert.net" target="_blank" rel="noopener noreferrer">Pieter Steyaert</a> © 2024</p>
    </div>
  </footer>
);

export default Footer;
