import React, { useEffect, useState } from 'react';
import client from './sanityClient';
import './App.css'; // Ensure you import your CSS file
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import theme from './components/theme';
import Header from './components/Header';
import Members from './components/Members';
import Activities from './components/Activities';
import Footer from './components/Footer';

function App() {
  const [members, setMembers] = useState([]);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    // Fetch members data
    client.fetch('*[_type == "member"]').then((data) => {
      setMembers(data);
    });

    // Fetch settings data
    client.fetch('*[_type == "settings"]').then((data) => {
      setSettings(data[0]); // Assuming there's only one settings document
    });
  }, []);

  if (!settings) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false} // Set maxWidth to false to allow full width
        sx={{
          backgroundColor: '#F49D7E',
          minHeight: '100vh',
          padding: 0,
          margin: 0,
        }}
      >
        <Box className="App" sx={{ backgroundColor: '#F49D7E' }}>
          <Header />
          <br/><br/><br/><br/>
          <Box sx={{ marginLeft: { xs: "0", md: "30px" }, padding: { xs: "0 10px", md: "0" } }}>
            <Box className="special-title">Exploring Exoplanets</Box>
            <br/>
            <Members members={[]} introductoryText={settings.introductoryText} color={"#1D4B6E"} />
            
            <br/><br/><br/><br/>
            <Box>
              <Activities activities={settings.activities} members={members} />
              <br/><br/>
              <Box className="special-title">Contributors</Box>
              <br/>
              <div style={{ paddingLeft: "15vw", paddingRight: "15vw" }}>
                <Members members={members} introductoryText={""} color={"#1D4B6E"}/>
              </div>
              
              <br/><br/><br/>
              <Footer footerText={settings.footerText} contact={settings.contact} />
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
