import React, { useState, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import imageUrlBuilder from '@sanity/image-url';
import client from '../sanityClient';
import Members from './Members'; // Import the Members module
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Activities = ({ activities, members }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const activityRefs = useRef([]);

  const handleOpen = (img, description) => {
    setSelectedImage(urlFor(img).width(800).url());
    setSelectedDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
    setSelectedDescription(null);
  };

  const scrollToActivity = (index) => {
    activityRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box>
      <Box className="special-title">Activities</Box>
      <br/><br/>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={3}>
          <List style = {{color: "#1D4B6E"}}>
            {activities.map((activity, index) => (
              <ListItem button key={index} onClick={() => scrollToActivity(index)}>
                <ListItemText primary={activity.title} secondary={`${activity.date}, ${activity.duration}`} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={1} md={1} />
        <Grid item xs={12} sm={8} md={8}>
          {activities.map((activity, index) => (
            <Box
              key={index}
              ref={(el) => (activityRefs.current[index] = el)}
              style={{ backgroundColor: activity.backgroundColor, color: activity.foregroundColor, padding: '20px', marginBottom: '20px' }}
            >
              {activity.graphic && (
                <img
                  src={urlFor(activity.graphic).width(800).url()}
                  alt={activity.title}
                  style={{ width: '100%' }}
                />
              )}
              <br /><br />
              <h2>{activity.title} <br/> <i>{activity.date}</i> </h2>
              <h3>{activity.duration}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>{activity.participants} participants</i></h3>

              <BlockContent blocks={activity.description} />

              {activity.supportiveImages && (
                <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                  {activity.supportiveImages.map((img, idx) => (
                    <Grid item xs={12} md={6} key={idx}>
                      <Box>
                        {img.image && (
                          <img
                            src={urlFor(img.image).width(800).url()}
                            alt={img.description.map((block) => block.children.map((child) => child.text).join(' ')).join(' ')}
                            style={{ width: '100%', cursor: 'pointer' }}
                            onClick={() => handleOpen(img.image, img.description)}
                          />
                        )}
                        <BlockContent blocks={img.description} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              {activity.members && activity.members.length > 0 && (
                <Box>
                  <br />
                  <h3>Facilitators</h3>
                  <Members
                    members={activity.members.map(memberRef => members.find(m => m._id === memberRef._ref)).filter(Boolean)}
                    color={activity.foregroundColor}
                    small
                  />
                </Box>
              )}
            </Box>
          ))}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              outline: 0,
            }}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Supportive"
                style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
              />
            )}
            {selectedDescription && (
              <Typography component="div">
                <BlockContent blocks={selectedDescription} />
              </Typography>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Activities;
