import React, { useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import imageUrlBuilder from '@sanity/image-url';
import client from '../sanityClient';
import { CSSTransition } from 'react-transition-group';
import './members.css'; // Import your CSS file for animations

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source).url();
}

const Members = ({ members, introductoryText, color, small }) => {
  const [expandedMember, setExpandedMember] = useState(null);
  const textcolor = color ? color : "black";

  const handleExpandClick = (id) => {
    setExpandedMember(expandedMember === id ? null : id);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={10} md={6} sx={{ color: textcolor }}>
          <h4>
            <BlockContent blocks={introductoryText} />
          </h4>
        </Grid>
      </Grid>
      {members && members.length !== 0 ? (
        <>
          <Grid container spacing={4}>
            {members.map((member) => (
              <Grid item xs={6} sm={(expandedMember === member._id)?  6 :small? 3 : 4} md={(expandedMember === member._id)?6:small? 2 : 3} key={member._id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <div className="image-container" onClick={() => handleExpandClick(member._id)}>
                    <img
                      src={urlFor(member.image)}
                      alt={member.name}
                      className="member-image"
                    />
                  </div>
                  {small? 
                  <h5 style={{ color: textcolor, marginTop: "10px" }}>{member.name}</h5>
                  : <h4 style={{ color: textcolor, marginTop: "10px" }}>{member.name}</h4>}
                  
                  <CSSTransition
                    in={expandedMember === member._id}
                    timeout={300}
                    classNames="slide"
                    unmountOnExit
                  >
                    <Box sx={{ marginTop: "3px", textAlign: 'left', color: textcolor }}>
                      <i>
                        <BlockContent blocks={member.description} />
                      </i>
                      {member.link && member.link !== "" ? (
                        <a href={member.link} target="_blank" rel="noopener noreferrer">Website</a>
                      ) : null}
                    </Box>
                  </CSSTransition>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}
    </div>
  );
};

export default Members;
