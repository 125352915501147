// Header.js
import React from 'react';
import header from "../resources/header.svg";
import header2 from "../resources/header2.svg";

const Header = () => (
  <div className="background-container">
    <div className="svg-container">
      <img src={header} className="responsive-svg" />
      <img src={header2} className="responsive-svg right-aligned" />
    </div>
  </div>
);

export default Header;
